.datePicker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid gray;
  height: 2rem;
  padding: 0.2rem;
  @media screen and (min-width: 768px) {
    width: 100%;
  }

}
.datePicker input {
  border: none;
  max-width: 100%;
}
.datePicker input:focus {
    outline: none;
  }


