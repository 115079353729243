/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*/
 @import "normalize.css";

@font-face {
  font-family: "Effra";
  src: url("assets/fonts/Effra/Effra_Std_Rg.otf") format("truetype");
}
* {
  font-family: "Effra" !important;

  box-sizing: border-box;
}
/* html {
  font-size: 777rem;
} */

.text-bold {
  font-weight: bold !important;
}

.text-gray,
.text-grey {
  color: rgba(255, 255, 0, 0.5) !important;
}

.bg-white {
  background: #fff !important;
} 
