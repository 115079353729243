.ShowNav {
    display: flex;
    height: 100%;
}

.HideNav {
    display: none;
}

.active-navbar-link{
    color: #003883;
}  

.toggle-navbar-menu-icon {
    font-size: 1.5rem;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

/* .space{
    padding: 5rem 0;
    height: 600px;
} */

