.social-icon {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  column-gap: 1rem;
}

.social-icon > a {
  width: 3rem;
  padding: 0.5rem ;
}

.social-icon > a > img {
  width: 100%;
}
